.wait {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.wait-message {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  color: antiquewhite;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
}

.spinner-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #5cb85c;
  margin-right: 5px;
  animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.spinner-dot:nth-child(2) {
  animation-delay: -0.4s;
}

.spinner-dot:nth-child(3) {
  animation-delay: -0.8s;
}

@keyframes spinner {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

.player-info {
  display: flex;
  align-items: center;
}

.player-label {
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px;
  color: rgb(204, 201, 201);
}

.player-shape {
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px;
  padding: 5px 10px;
  border-radius: 5px;
}

.red {
  background-color: #DC493A;
  color: #fff;
}

.blue {
  background-color: #4392f1;
  color: #fff;
}

.turn-message {
  font-size: 18px;
  font-weight: bold;
  margin-left: 10px;
  color: #5cb85c;
}

.waiting-message {
  font-size: 18px;
  font-weight: bold;
  margin-left: 10px;
  color: #f9a825;
}

.room-code {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  padding: 10px 20px;
  color: #5cb85c;
  border-radius: 5px;
  border: 2px solid #5cb85c;
}

.code {
  font-size: 36px;
  font-weight: bold;
  margin-left: 10px;
  color: #5cb85c;
}

.share-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }
  
  .share-button {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    border-radius: 12px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    font-family: 'Comic Sans MS', cursive, sans-serif;
  }
  
  .share-button:hover {
    background-color: white; 
    color: black; 
    border: 2px solid #4CAF50;
  }
  
  .copied-message {
    color: red;
    margin-top: 10px;
    font-size: 12px;
    font-family: 'Comic Sans MS', cursive, sans-serif;
  }
  
  