.countdown-timer {
  font-size: 1rem;
  text-align: center;
  position: relative;
  display: inline-block;
  margin-top: 1.875rem;
}

.field {
  width: 100%;
  height: 20px;
  background: #4caf50; /* Color del rastro de la pelota */
  position: relative;
  border-radius: 15px;
  overflow: hidden;
}

.track {
  position: absolute;
  height: 100%;
  background: #e0e0e0; /* Color de fondo del campo */
  left: 0;
  transition: background 0.5s;
}

/* Colores de advertencia y peligro */
.countdown-timer.warning .field {
  background: #ff9800;
}

.countdown-timer.danger .field {
  background: #f44336;
}

.ball {
  width: 20px; /* Reduce el tamaño de la pelota */
  height: 20px;
  background-size: cover;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
}

.time-text {
  color: rgb(200, 199, 199);
  margin-top: 10px;
  font-weight: bold;
  font-size: 1.2rem;
}

.seconds {
  color: green; /* Color del texto */
  font-weight: bold;
  padding: 5px;
  border-radius: 5px;
  font-size: 1.2rem;
  transition: background 0.5s; /* Transición suave del color */
}

/* Colores de advertencia y peligro */
.countdown-timer.warning .seconds {
  color: #ff9800;
}

.countdown-timer.danger .seconds {
  color: #f44336;
}
