.square {
    position: relative;
    width: 100px;
    height: 100px;
    background-color: #848484;
    margin: 5px;
    transition: all .2s;
    border-radius: 0%;
    clip-path: polygon(36% 0, 0 0, 0 29%, 0 57%, 0 100%, 30% 100%, 73% 100%, 100% 100%, 100% 59%, 100% 31%, 100% 0, 74% 0);
}

.player-name {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 11px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.6); 
    border-radius: 10px;
    padding: 2px 10px;
    pointer-events: none; 
}

.square:hover {
    background-color: #BDBDBD;
    cursor: pointer;
}

.square--X {
    background-color: #DC493A;
    clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
}

.square--X:hover {
    background-color: #DC493A;
}

.square--O {
    background-color: #4392f1;
    border-radius: 50%;
}

.square--O:hover {
    background-color: #4392f1;
}

.square--X.winner {
    animation: x-winner-animation infinite 2s ease-in-out;
}

.square--O.winner {
    animation: o-winner-animation infinite .5s ease-in-out;
}

@keyframes x-winner-animation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes o-winner-animation {
    0% {
        border-radius: 50%;
    }

    50% {
        border-radius: 0%;
    }

    100% {
        border-radius: 50%;
    }
}

/* media screen max width or max height */



@media only screen and (max-width: 768px), (max-height: 1024px) {
    .square {
        width: 90px;
        height: 90px;
        margin: 3px;
    }
}

@media only screen and (max-width: 480px), (max-height: 800px) {
    .square {
        width: 70px;
        height: 70px;
        margin: 2px;
    }
}