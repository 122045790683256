.game-header {
    text-align: center;
  }
  
  .game-title {
    font-family: 'Fredoka One', cursive;
    font-size: 2rem;
    text-transform: uppercase;
    color: #4caf50;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    animation: colorchange 3s infinite;
    cursor: pointer;
  }

  @keyframes colorchange {
    0% {
      color: #4caf50;
    }
    25% {
      color: #f44336;
    }
    50% {
      color: #2196f3;
    }
    75% {
      color: #ffeb3b;
    }
    100% {
      color: #4caf50;
    }
  }
  
  @media (max-width: 767px) {
    .game-title {
      font-size: 36px;
    }
  }
  
  /* Media query para pantallas muy pequeñas */
  @media (max-width: 480px) {
    .game-title {
      font-size: 24px;
    }
  }