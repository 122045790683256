.board {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 400px;
    margin-top: 20px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
}

@keyframes colorChange {
    0% { color: #1abc9c; }
    50% { color: #2ecc71; }
    100% { color: #1abc9c; }
}

.waiting-bot-turn {
    padding: 10px;
    animation: pulse 2s ease-in-out infinite, colorChange 2s linear infinite;
    display: inline-block;
    font-weight: bold;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.waiting-bot-turn::after {
    content: "⚽️";
    font-size: 20px;
    position: relative;
    left: 10px;
    animation: pulse 1s ease-in-out infinite alternate, colorChange 2s linear infinite;
}


.logo {
    width: 100px;
    height: 100px;
    /* width 100% for responsiveness /
    height: auto; / change height to auto for responsiveness */
    margin: 5px;
    border-radius: 0%;
}

.row {
    display: flex;
}

@media only screen and (max-width: 768px), (max-height: 1024px) {
    .logo {
        width: 90px;
        height: 90px;
        margin: 3px;
    }
}

@media only screen and (max-width: 480px), (max-height: 800px) {
    .logo {
        width: 70px;
        height: 70px;
        margin: 2px;
    }
}