.how-to-play-button {
    display: block;
    margin: 20px auto;
    background-color: none;
    color: #333;
    font-size: 16px;
    padding: 8px 16px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}

.how-to-play-button:hover {
    background-color: none;
    color: white;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.x-icon {
  width: 100%;
  height: auto;
}

.how-to-play-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
  
  .how-to-play-content {
    background-color: white;
    border-radius: 5px;
    max-width: 90%; /* Tamaño predeterminado para dispositivos pequeños */
    max-height: 90%; /* Tamaño predeterminado para dispositivos pequeños */
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
  
  .how-to-play-header {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
  }
  
  .how-to-play-body {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
  }
  
  .how-to-play-carousel {
    width: 100%;
    height: 0;
    padding-bottom: 75%; /* Altura predeterminada para dispositivos pequeños */
    position: relative;
    overflow: hidden;
}
  
  .how-to-play-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    opacity: 0;
    transition: opacity 0.5s;
  }
  
  .how-to-play-image.active {
    opacity: 1;
  }
  
  .how-to-play-text {
    margin-top: 1rem;
  }

.carousel-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 1s;
}

.carousel-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.carousel-slide.active {
    opacity: 1;
    z-index: 1;
}

@media (min-width: 768px) {
    .how-to-play-content {
        max-width: 60%; /* Aumenta el ancho del modal en pantallas medianas */
        max-height: 80%; /* Aumenta la altura del modal en pantallas medianas */
    }

    .how-to-play-carousel {
        padding-bottom: 50%; /* Aumenta la altura del contenedor de la imagen en pantallas medianas */
    }
}

/* Estilos para pantallas grandes */
@media (min-width: 1200px) {
    .how-to-play-content {
        max-width: 40%; /* Aumenta el ancho del modal en pantallas grandes */
        max-height: 80%; /* Aumenta la altura del modal en pantallas grandes */
    }

    .how-to-play-carousel {
        padding-bottom: 55%; /* Aumenta la altura del contenedor de la imagen en pantallas grandes */
    }
}