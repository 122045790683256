.player-selected-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 14px;
    color: azure;
}

.player-selected-country {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.player-selected-country img {
    margin-right: 5px;
}

.player-selected-country span:first-child {
    margin-right: 5px;
    font-weight: bold;
}


