/* Estilos base */
.login-container {
    display: flex;
    align-items: center;
    min-height: 70vh;
    background-color: transparent;
}

.login-box {
    padding: 30px; /* Aumentar el padding */
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    background-color: rgb(244, 244, 244);
    max-width: 500px; /* Aumentar el ancho máximo */
    width: 90%; /* Aumentar el width para pantallas pequeñas */
}

.login-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px; /* Aumentar el margen inferior */
}

.home-icon {
    margin-right: 15px; /* Aumentar el margen derecho */
    font-size: 28px; /* Aumentar el tamaño de fuente */
    text-decoration: none;
}

.login-form input, .login-form button {
    margin: 0;
    padding: 0;
}

.login-form input {
    width: 100%;
    padding: 12px; /* Aumentar el padding */
    margin-bottom: 15px; /* Aumentar el margen inferior */
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.login-form button {
    width: 100%;
    padding: 12px; /* Aumentar el padding */
    background-color: #0B111E;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.login-form button:hover {
    background-color: #000207;
}

.error-text {
    color: red;
    margin-top: 20px; /* Aumentar el margen superior */
}

.login-links {
    display: flex;
    justify-content: space-between;
    margin-top: 30px; /* Aumentar el margen superior */
}

.login-links a {
    color: #0B111E;
    text-decoration: none;
}

.login-links a:hover {
    text-decoration: underline;
}

/* Media Queries */
@media (max-width: 768px) {
    .login-box {
        width: 95%; /* Aumentar el width para pantallas medianas */
    }
}

@media (max-width: 480px) {
    .login-box {
        width: 100%; /* Aumentar el width para pantallas pequeñas */
        padding: 20px; /* Reducir el padding para pantallas pequeñas */
    }
}

