/* Primer, segundo y tercer puesto destacados */
.ranking-table tbody tr:first-child td {
    background-color: #ffd700; /* Oro brillante */
    animation: shine 1.5s infinite alternate;
}

.ranking-table tbody tr:nth-child(2) td {
    background-color: #c0c0c0; /* Plata brillante */
    animation: shine 1.5s infinite alternate;
}

.ranking-table tbody tr:nth-child(3) td {
    background-color: #cd7f32; /* Bronce brillante */
    animation: shine 1.5s infinite alternate;
}

/* Animación de brillo */
@keyframes shine {
    0% {
        box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
    }
    100% {
        box-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
    }
}

/* Resto del CSS */
.container {
    padding: 20px;
}

.page-title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #0d0533;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.mode-selector {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.mode-selector label {
    font-size: 1rem;
    color: #666;
    margin-right: 8px;
}

.mode-selector select {
    padding: 4px 6px;
    font-size: 0.9rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #f2f2f2;
    color: #666;
    transition: background-color 0.3s ease;
    height: 32px;
    vertical-align: middle;
}

.mode-selector select:focus {
    outline: none;
    background-color: #e1e1e1;
}

.table-wrapper {
    max-width: 100%;
    overflow-x: auto;
    margin: 15px 0;
}

.ranking-table {
    width: 90%;
    border-collapse: collapse;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
}

.ranking-table th, .ranking-table td {
    border: 1px solid #ddd;
    padding: 10px 12px;
    text-align: center;
    font-size: 0.9rem;
    white-space: nowrap;
    min-width: 60px;
}

.ranking-table th {
    background-color: #0d0533;
    color: white;
    font-weight: 600;
}

.ranking-table td.total-points,
.ranking-table td.username {
    background-color: #e4e3ff;
    color: #0d0533;
    font-weight: bold;
}

.ranking-table tr:nth-child(even) {
    background-color: #f7f7f7;
}

.ranking-table tr:hover {
    background-color: #e1f5e4;
    transition: background-color 0.3s ease;
}

.fixed-width {
    width: 100px;
}

@media (max-width: 768px) {
    .ranking-table {
        font-size: 0.8rem;
    }

    .ranking-table th, .ranking-table td {
        padding: 6px 8px;
    }

    .page-title {
        font-size: 2rem;
    }

    .mode-selector select {
        font-size: 0.85rem;
    }
}

@media (max-width: 480px) {
    .ranking-table {
        font-size: 0.7rem;
    }

    .ranking-table th, .ranking-table td {
        padding: 4px 6px;
    }

    .page-title {
        font-size: 1.8rem;
    }

    .mode-selector select {
        font-size: 0.8rem;
    }
}
