.logout-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #F7F7F7;
}

/* Nuevo contenedor para el spinner y el texto */
.logout-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.logout-content {
    text-align: center;
    color: #0B111E;
    margin-top: 20px; /* Agregar margen superior para separar el texto del spinner */
}

.spinner {
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 8px solid #0B111E;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin-bottom: 20px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
