/* Estilos base */
.register-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 70vh;
    background-color: transparent
}

.register-box {
    padding: 30px; /* Aumentar el padding */
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    background-color: white;
    max-width: 500px; /* Aumentar el ancho máximo */
    width: 90%; /* Aumentar el width para pantallas pequeñas */
}

.register-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px; /* Aumentar el margen inferior */
}

.home-icon {
    margin-right: 15px; /* Aumentar el margen derecho */
    font-size: 28px; /* Aumentar el tamaño de fuente */
    text-decoration: none;
}

.register-form input, .register-form button {
    margin: 0;
    padding: 0;
}

.register-form input {
    width: 100%;
    padding: 12px; /* Aumentar el padding */
    margin-bottom: 15px; /* Aumentar el margen inferior */
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.register-form select {
    width: 100%;
    padding: 12px; /* Aumentar el padding */
    margin-bottom: 15px; /* Aumentar el margen inferior */
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.register-form button {
    width: 100%;
    padding: 12px; /* Aumentar el padding */
    background-color: #0B111E;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}x

.register-form button:hover {
    background-color: #000207;
}

.error-text {
    color: red;
    margin-top: 20px; /* Aumentar el margen superior */
}

.register-links {
    display: flex;
    justify-content: space-between;
    margin-top: 30px; /* Aumentar el margen superior */
}

.register-links a {
    color: #0B111E;
    text-decoration: none;
}

.register-links a:hover {
    text-decoration: underline;
}

.confirmation-text {
    margin-top: 20px; /* Aumentar el margen superior */
    text-align: center;
}

.confirmation-code {
    font-weight: bold;
    color: #0B111E;
}

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Media Queries */
@media (max-width: 768px) {
    .register-box {
        width: 95%; /* Aumentar el width para pantallas medianas */
    }
}

@media (max-width: 480px) {
    .register-box {
        width: 100%; /* Aumentar el width para pantallas pequeñas */
        padding: 20px; /* Reducir el padding para pantallas pequeñas */
    }
}