.next-button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

.next-button{
    background-color: transparent;
    color: azure;
    border: 1px solid #ced4da;
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    font-size: 0.7rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin: 0 0.5rem;
}

.next-button:hover{
    background-color: #ced4da;
    color: #000;
}

.skip-turn-button{
    background-color: rgb(201, 5, 5);
    color: azure;
    border: 1px solid #ced4da;
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    font-size: 0.7rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin: 0 0.5rem;
}

.skip-turn-button:hover{
    background-color: rgb(61, 1, 1);
    color: azure;
}


.turn-indicator-blue p{
    font-weight: bold;
    background-color: #4392f1;
    color: azure;
    border: 1px solid #ced4da;
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    font-size: 0.7rem;
    margin: 0 0.5rem;
}

.turn-indicator-red p{
    font-weight: bold;
    background-color: #DC493A;
    color: azure;
    border: 1px solid #ced4da;
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    font-size: 0.7rem;
    margin: 0 0.5rem;
}