@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');


.game-title {
  font-family: 'Fredoka One', cursive;
  font-size: 2rem;
  text-transform: uppercase;
  color: #4caf50;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  animation: colorchange 3s infinite;
}

@keyframes colorchange {
  0% {
    color: #4caf50;
  }
  25% {
    color: #f44336;
  }
  50% {
    color: #2196f3;
  }
  75% {
    color: #ffeb3b;
  }
  100% {
    color: #4caf50;
  }
}

@media (max-width: 767px) {
  .game-title {
    font-size: 36px;
  }
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: #f5f5f5;
  font-family: 'Anton', sans-serif;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  max-width: 800px;
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .form-grid {
    grid-template-columns: 1fr;
  }
}

.form-field {
  margin-bottom: 20px;
}

.form-field label {
  display: block;
  margin-bottom: 10px;
  color: #efece1;
}

.form-field select, .form-field input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 2px solid #efece1;
  background-color: #333;
  color: #efece1;
  box-sizing: border-box;
  height: 50px;
}

.go-home-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.goHomeIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100px; /* Aumenta este valor si el texto no cabe dentro del botón */
  height: 100px; /* Aumenta este valor si el texto no cabe dentro del botón */
  position: absolute;
  top: 10px;
  left: 10px;
}


button {
  padding: 10px 20px;
  font-size: 16px;
  margin-left: 10px;
  color: rgb(224, 224, 224);
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.searchButton {
  background-color: #01ac20;
}

.resetButton{
  background-color: #fa0303;
}

.resetButton:hover{
  background-color: #a90303;
}

.searchButton:hover {
  background-color: #047216;
}

.searchButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  color:#333;
}

.results-container {
  max-width: 800px;
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.result-item {
  font: 16px 'Trebuchet MS', sans-serif;
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  width: 100%;
  max-width: 800px;
  background-color: #333;
  color: #efece1;
  border-radius: 10px;
  text-align: center;
  font-size: 18px;
  transition: all 0.3s ease;
}

.result-item:hover {
  transform: scale(1.05);
}






  