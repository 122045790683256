.app-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
}

.button-group {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.button-group button {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

.button-group button.active {
    background-color: #007bff;
    color: #ffffff;
}

.button-group button:not(.active) {
    background-color: #f0f0f0;
    color: #000000;
}

.button-group button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}

.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background-color: transparent;
    width: 100%;
    max-width: 100%;
    margin: auto center !important;
}

.player-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    padding: 2rem;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.form-group {
    flex: 1;
    min-width: calc(50% - 1rem);
    display: flex;
    flex-direction: column;
}

label {
    font-weight: 500;
    margin-bottom: 0.5rem;
    display: block;
    color: #495057;
    font-family: 'Merriweather', serif;
}

.form-input,
.form-select {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: #ffffff;
    transition: border-color 0.3s, box-shadow 0.3s;
    box-sizing: border-box;
}

.form-input:focus,
.form-select:focus {
    border-color: #80bdff;
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-button {
    padding: 1rem;
    font-size: 1.1rem;
    color: #ffffff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
}

.form-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}

.selected-items {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 0.5rem;
}

.selected-item {
    display: flex;
    align-items: center;
    background-color: #007bff;
    color: #ffffff;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
}

.selected-item button {
    background: none;
    border: none;
    color: #ffffff;
    cursor: pointer;
    margin-left: 0.5rem;
    font-size: 1.2rem;
}

.form-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

.cancel-button {
    background-color: #dc3545;
}

.cancel-button:hover {
    background-color: #c82333;
}

.delete-button {
    background-color: #dc3545;
}

.delete-button:hover {
    background-color: #c82333;
}

@media (max-width: 768px) {
    .form-container {
        padding: 1rem;
    }

    .player-form {
        padding: 1rem;
    }

    .form-row {
        flex-direction: column;
    }

    .form-group {
        min-width: 100%;
    }

    .form-buttons {
        flex-direction: column;
        gap: 1rem;
    }

    .form-button {
        max-width: 100%;
    }
}

.search-players-container {
    margin-bottom: 1rem;
    width: 100%;
}

.search-input-container {
    position: relative;
}

.search-input {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
}

.search-select-container {
    margin-top: 0.5rem;
}

.search-results {
    list-style: none;
    padding: 0;
    margin-top: 0.5rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: #ffffff;
}

.search-results li {
    padding: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

.search-results li:hover {
    background-color: #f1f1f1;
}