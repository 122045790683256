.search-players-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.search-input-container {
    margin-top: 5%;
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.search-select-container {
    margin-bottom: 18.75rem;
}

.search-input {
    min-width: 15em;
    padding: 1.5rem 2.5rem 1.5rem 1rem;
    margin-top: 1em;
    font-size: 1rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    background-color: #fff;
    background-image: none;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.select-input {
    font-size: 0.3rem;
    padding: 0.5rem 2.5rem 0.5rem 1rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    background-color: #fff;
    background-image: none;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.player-option {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow-x: auto;
    font-size: small;
}

.player-option img {
    margin-right: 0.5rem;
    border-radius: 50%;
}

@media only screen and (min-width: 768px), (max-height: 800px) {
    .search-input {
        width: 60%;
    }
}

@media only screen and (max-width: 480px), (max-height: 800px) {
    .search-input {
        width: 40%;
    }
}

.react-select__option {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow-x: auto;
    text-overflow: ellipsis;
    padding: 0.75rem;
}

.react-select__single-value {
    white-space: nowrap;
    overflow-x: auto;
    text-overflow: ellipsis;
}

.react-select__menu {
    max-height: 200px;
    overflow-y: auto;
}

