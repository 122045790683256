.app-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;
    width: 100%;
}

.button-group {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.button-group button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.button-group button.active {
    background-color: #007bff;
    color: white;
}

.button-group button:not(.active) {
    background-color: #f0f0f0;
    color: #333;
}

.button-group button.argentina {
    background-color: #74acdf; /* Celeste */
    color: white;
}

.button-group button.argentina.active {
    background-color: #ffffff; /* Blanco */
    color: #74acdf; /* Celeste */
}

.page-title {
    margin-top: 1rem;
    font-size: 1.5rem;
    color: aliceblue;
}

@media (max-width: 600px) {
    .button-group {
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .button-group button {
        width: 100%;
    }
}
