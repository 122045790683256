.search-players-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 100%;
    padding: 0; /* Eliminar padding para que esté más cerca de los botones */
    box-sizing: border-box;
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: transparent;
}

.search-input-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 0; /* Eliminar margen superior */
    margin-bottom: 1rem; /* Reducir margen inferior */
}

.search-input {
    width: 100%;
    max-width: 600px;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    background-color: #fff;
    color: #333;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.search-input:focus {
    border-color: #007bff;
    outline: none;
}

.search-select-container {
    width: 100%;
    max-width: 600px;
    margin-bottom: 1rem; /* Reducir margen inferior */
}

.react-select__control {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.3s, box-shadow 0.3s;
    box-shadow: none;
    background-color: #fff;
    color: #333;
}

.react-select__control--is-focused {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.react-select__menu {
    border-radius: 0.25rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-height: 300px;
    overflow-y: auto;
    background-color: #fff;
    color: #333;
}

.react-select__option {
    padding: 0.75rem;
    font-size: 1rem;
    color: #333;
    display: flex;
    align-items: center;
    background-color: #fff;
}

.react-select__option img {
    margin-right: 0.5rem;
    border-radius: 50%;
}

.react-select__option--is-focused {
    background-color: #007bff;
    color: #fff;
}

.react-select__option--is-selected {
    background-color: #0056b3;
    color: #fff;
}

.react-select__single-value {
    font-size: 1rem;
    color: #333;
}

.react-select__placeholder {
    font-size: 1rem;
    color: #999;
}

@media only screen and (min-width: 768px) {
    .search-input {
        max-width: 60%;
    }
}

@media only screen and (max-width: 480px) {
    .search-input {
        max-width: 90%;
    }
}
