.score-board {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #979494;
    border-radius: 20px;
    padding: 5px 15px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    margin-top: 1rem;
    width: 110px;
}

.team-score {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #e6e4e4;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.team-score .score {
    font-size: 20px;
    font-weight: bold;
}

.team-x .score {
    color: #f44336;
}

.team-o .score {
    color: #2196f3;
}

.separator {
    font-size: 24px;
    font-weight: bold;
    margin: 0 0.5rem;
}


