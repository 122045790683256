.difficulty-streak-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.difficulty-selector-wrapper {
  display: flex;
  align-items: center;
  font-family: Arial, sans-serif;
  color: #fff;
  margin-right: 15px;
}

.difficulty-selector {
  margin-left: 10px;
  height: 25px;
  border: none;
  border-radius: 3px;
  padding: 3px 8px;
  background: #444;
  color: #fff;
  font-size: 14px;
  transition: all 0.3s ease;
}

.difficulty-selector:hover {
  background: #555;
}

.difficulty-selector:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.streak-container {
  position: relative;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flame-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  bottom: 15%;
}

.streak-number {
  position: absolute;
  top: 65%;
  left: 52%;
  transform: translate(-50%, -50%);
  color: white; /* Color del texto */
  font-size: 14px;
  font-weight: bold;
  text-shadow: 
    2px 2px 3px rgba(0, 0, 0, 0.8), 
    -2px -2px 3px rgba(0, 0, 0, 0.8); /* Contorno del texto */
}