.game-mode-actions {
    display: flex;
    flex-direction: column; /* Cambia la dirección de los elementos a columna */
    align-items: center; /* Alinea los elementos al centro */
    gap: 10px; /* Añade espacio entre los elementos */
  }
  
  .play-bot-button {
    font-size: 18px;
    font-weight: bold;
    padding: 10px 20px;
    background-color: #96c80e;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 10px;
  }
  
  .play-local-button {
    font-size: 18px;
    font-weight: bold;
    padding: 10px 20px;
    background-color: #b30b59;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 10px;
  }
  
  .play-online-button {
    font-size: 18px;
    font-weight: bold;
    padding: 10px 20px;
    background-color: #01645e;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 10px;
  }
  
  .play-local-button:hover, .play-online-button:hover, .play-bot-button:hover {
    opacity: 0.8;
  }
  
  .play-local-button:active, .play-online-button:active, .play-bot-button:active {
    transform: scale(0.95);
  }
  .vote-league-button {
    font-size: 18px;
    font-weight: bold;
    padding: 15px 25px;
    background-color: #faf24c;
    color: #000;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin: 20px 20px 20px 20px; /* El margen superior lo separará de los otros botones */
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease 0s;
    text-transform: uppercase;
    width: 100%; /* Ocupará todo el ancho disponible */
}

.vote-league-button:hover {
    background-color: #ffd740;
    box-shadow: 0px 15px 20px rgba(255, 202, 40, 0.4);
    transform: translateY(-5px);
}

  
  @media only screen and (max-width: 480px) {
    .play-local-button, .play-online-button, .play-bot-button {
      font-size: 16px;
    }
  
    html, body {
      max-width: 100%;
      overflow-x: hidden;
    }
  }

  .info-button {
    font-size: 18px;
    font-weight: bold;
    padding: 10px 20px;
    background-color: #d63b18;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 10px;
}

.info-button:hover {
    opacity: 0.8;
}

.info-button:active {
    transform: scale(0.95);
}

.configure-room-button {
    font-size: 18px;
    font-weight: bold;
    padding: 10px 20px;
    background-color: #6c757d;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 10px;
}

.configure-room-button:hover {
    opacity: 0.8;
}

.configure-room-button:active {
    transform: scale(0.95);
}

.join-room-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.join-room-content {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    max-width: 400px;
    width: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.join-room-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.join-room-header h3 {
    margin: 0;
    font-size: 24px;
    color: #333333;
}

.close-modal-button {
    border: none;
    border-radius: 50%;
    width: 1.625rem;
    height: 1.625rem;
    font-size: 0.875rem;
    line-height: 1.625rem;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    padding: 0;
    background: none;
}


.join-room-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.join-room-form label {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
}

.join-room-form select {
    padding: 5px 10px;
    font-size: 16px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    color: #333333;
}

.other-modes-button {
    font-size: 15px;
    font-weight: bold;
    padding: 10px 20px;
    background-color: #2561f9;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 30px 10px 0 0;
}

.other-modes-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.other-modes-content {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    width: 350px;
}

.other-modes-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.other-modes-header h3 {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
}

.other-modes-form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.football-button {
    font-size: 15px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background: linear-gradient(to right, #2561f9, #6a11cb);
    color: #fff;
    margin: 10px 10px;
    display: flex;
    align-items: center;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    height: 50px; /* Ajusta este valor para cambiar la altura de los botones */
    justify-content: center;
}

.football-button:hover {
    background: linear-gradient(to right, #2a75f3, #7c12d6);
    box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
    color: #fff;
    transform: translateY(-7px);
}

.football-button img {
    margin-right: 10px;
    height: 30px; /* Ajusta este valor para cambiar la altura de las imágenes */
}



.coming-soon-text {
    font-size: 16px;
    color: #333333;
}

.football-logo {
    width: 30px;
    /* Ajusta este valor al tamaño que prefieras */
    height: auto;
    /* Esto mantiene la proporción de la imagen */
}

.x-icon {
    width: 100%;
    height: auto;
}



@media only screen and (max-width: 480px) {
    .join-room-content {
        width: 90%;
    }

    .create-room-button,
    .join-room-button {
        font-size: 16px;
    }
}