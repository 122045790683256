.team {
    width: 100px;
    height: 100px;
    background-color: wheat;
    color: black;
    margin: 5px;
    border-radius: 0%;
}

@media only screen and (max-width: 768px), (max-height: 1024px) {
    .team {
        width: 90px;
        height: 90px;
        margin: 3px;
    }
}

@media only screen and (max-width: 480px), (max-height: 800px) {
    .team {
        width: 70px;
        height: 70px;
        margin: 2px;
    }
}