.room-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
  }
  
  .create-room-button {
    font-size: 18px;
    font-weight: bold;
    padding: 10px 20px;
    margin-right: 10px;
    background-color: #5cb85c;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 10px;
  }
  
  .create-room-button:hover, .join-room-button:hover {
    opacity: 0.8;
  }
  
  .create-room-button:active, .join-room-button:active {
    transform: scale(0.95);
  }
  
  .find-players-button {
      font-size: 14px;
      font-weight: bold;
      padding: 10px 20px;
      background-color: #f9a825;
      color: #fff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      margin: 10px 10px;
  }
  
  .find-players-button:hover {
    opacity: 0.5;
  }
  
  .join-room {
    position: relative;
  }
  
  .join-room-button {
    font-size: 18px;
    font-weight: bold;
    padding: 10px 20px;
    background-color: #f9a825;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 10px;
  }
  
  .join-room-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .join-room-content {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    width: 400px;
  }
  
  .join-room-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .join-room-header h3 {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
  }
  
  .close-modal-button {
    background-color: transparent;
    border: none;
    font-size: 24px;
    color: #555;
    cursor: pointer;
  }
  
  .join-room-form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .join-room-form input {
    font-size: 18px;
    padding: 10px;
    border: none;
    border-bottom: 2px solid #f9a825;
    margin-bottom: 10px;
    color: #555;
  }
  
  .join-room-submit-button {
    font-size: 18px;
    font-weight: bold;
    padding: 10px 20px;
    background-color: #f9a825;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }