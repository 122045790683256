/* Navbar.css */
.navbar {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    background-color: #0B111E;
    height: 60px;
    padding: 0 20px;
    margin-top: 20px;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1000px;
}

.navbar-menu {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    align-items: center;
    flex-wrap: wrap;
}

.navbar-menu li {
    margin: 0 10px;
}

.navbar-menu li a {
    color: #fff;
    font-size: 20px;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.navbar-menu li p {
    color: #fff;
    font-size: 16px;
    display: flex;
    align-items: center;
}

.navbar-menu li p svg {
    margin-right: 8px; /* Espacio entre el ícono y el nombre de usuario */
}

.navbar-menu button svg {
    margin-right: 8px; /* Espacio entre el ícono y el nombre de usuario */
}

.navbar-menu button {
    background-color: transparent;
    color: #fff;
    font-size: 14px;
    border: 1px solid #fff;
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.navbar-menu .logout-button {
    color: #ff4c4c;
    border: none;
    font-size: 18px;
    display: flex;
    align-items: center;
    background: none;
    padding: 0;
}

.navbar-menu .logout-button:hover {
    opacity: 0.8;
}

.navbar-menu .game-icon {
    font-size: 20px;
}

@media (max-width: 768px) {

    .navbar-menu li {
        margin: 0 10px;
    }

    .navbar-menu button {
        font-size: 12px;
        padding: 6px 12px;
    }

    .navbar-menu li p {
        font-size: 14px;
    }
}